<template>
  <section class="all-cases-view">
    <el-row justify="center">
      <el-col :span="20">
        <div class="container-default">
          <div class="listings-navbar">
            <el-row>
              <el-col :lg="7">
                <ul class="d-flex list-items-group-15 barcode-print">
                  <li>
                    <el-button
                      class="btn button-with-icon"
                      @click="createNewConfig"
                    >
                      <span class="mr-8">
                        <img
                          src="@/assets/images/icons/plus-icon.svg"
                          alt="icon"
                          class="inactive"
                        />
                        <img
                          src="@/assets/images/icons/plus-icon-white.svg"
                          alt="icon"
                          class="active"
                        />
                      </span>
                      <span>INSTRUMENT TEST CONFIG</span>
                    </el-button>
                  </li>
                </ul>
              </el-col>
              <el-col :lg="17">
                <ul
                  class="d-flex flex-wrap justify-content-end list-items-group-15"
                >
                  <li>
                    <div class="form-element search-with-filter-input">
                      <el-select
                        v-model="searchBy"
                        clearable
                        filterable
                        default-first-option
                        :reserve-keyword="false"
                        placeholder="Select Search"
                        @change="clearInputSearch"
                      >
                        <el-option
                          v-for="item in searchOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                      <span class="search-icon">
                        <img
                          src="@/assets/images/icons/search-icon-with-bg-red.svg"
                          alt="icon"
                          class="img-fluid"
                        />
                      </span>
                    </div>
                  </li>

                  <li v-if="isSearchByInput">
                    <div class="form-element input-with-icon search-input-lg">
                      <el-input
                        placeholder="Search Assay Name"
                        v-model="search_string"
                        @input="getAutoCompleteCases()"
                        :clearable="true"
                      ></el-input>
                      <span>
                        <img
                          src="@/assets/images/icons/search-icon-with-bg-red.svg"
                          alt="icon"
                          class="img-fluid icon"
                        />
                      </span>
                    </div>
                  </li>

                  <li v-else-if="searchBy == 'search_instrument'">
                    <div class="form-element search-with-filter-input">
                      <el-select
                        placeholder="Select Instrument"
                        v-model="search_instrument"
                        filterable
                        clearable
                        default-first-option
                        @change="fetchBloodTestConfig(1)"
                      >
                        <el-option
                          v-for="status in instruments"
                          :key="status.value"
                          :value="status.value"
                          :label="status.name"
                        ></el-option>
                      </el-select>
                      <span class="search-icon">
                        <img
                          src="@/assets/images/icons/search-icon-with-bg-red.svg"
                          alt="icon"
                          class="img-fluid"
                        />
                      </span>
                    </div>
                  </li>
                </ul>
              </el-col>
            </el-row>
          </div>
          <div class="primary-content-view-new" v-loading="loading">
            <div
              class="listings listings__table listings__all-cases"
              id="all-cases-listings"
            >
              <el-table
                :data="testData.data"
                :default-sort="{
                  prop: orderBy,
                  order: orderType == 'asc' ? 'ascending' : 'descending',
                }"
                @sort-change="onChangeSort"
              >
                <el-table-column
                  prop="instrument"
                  label="Instrument"
                  min-width="120"
                  sortable="custom"
                >
                  <template v-slot="scope"
                    ><span @click="onView(scope.row)" class="cursor">{{
                      scope.row.instrument
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="test_name"
                  label="Test Name"
                  min-width="150"
                  sortable="custom"
                >
                  <template v-slot="scope">{{
                    scope.row.test_name ? scope.row.test_name : "--"
                  }}</template>
                </el-table-column>
                <el-table-column
                  prop="assay_name"
                  label="Assay Name"
                  min-width="180"
                >
                  <template v-slot="scope">{{ scope.row.assay_name }}</template>
                </el-table-column>

                <el-table-column
                  label="Created By"
                  prop="created_by"
                  min-width="90"
                  sortable="custom"
                >
                  <template v-slot="scope">
                    {{
                      scope.row.created_by
                        ? scope.row.created_by.first_name +
                          " " +
                          scope.row.created_by.last_name
                        : "--"
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label=" Created At"
                  prop="created_at"
                  min-width="90"
                  sortable="custom"
                >
                  <template v-slot="scope">
                    <div v-if="scope.row.created_at" class="d-flex">
                      <div class="date pr-10">
                        {{
                          momentWithTimezone(scope.row.created_at, "MM-DD-yyyy")
                        }}
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="Is Active" min-width="120" v-if="false">
                  <template v-slot="{ row }">
                    <el-switch
                      v-model="row.is_active"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      @change="toggleActiveStatus($event, row)"
                    >
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="action"
                  label="ACTIONS"
                  fixed="right"
                  width="160"
                >
                  <template v-slot="scope">
                    <ul class="listings__action-buttons">
                      <li @click="onView(scope.row)">
                        <el-button class="view-button">
                          <img
                            class="action-active"
                            src="@/assets/images/icons/actions/view-icon.svg"
                            alt="icon"
                          />
                          <img
                            class="action-inactive"
                            src="@/assets/images/icons/actions/view-inactive.svg"
                            alt="icon"
                          />
                        </el-button>
                      </li>
                      <li>
                        <el-button
                          class="view-button"
                          @click="onLevelView(scope.row)"
                        >
                          View Levels
                        </el-button>
                      </li>
                    </ul>
                  </template>
                </el-table-column>
              </el-table>
              <div class="listings listings__pagination">
                <el-pagination
                  v-model:currentPage="page"
                  v-model:page-size="pageSize"
                  :page-sizes="[20, 50, 80, 100]"
                  :small="small"
                  :disabled="disabled"
                  :background="background"
                  layout=" prev, pager, next, jumper, sizes ,total"
                  :total="testData.total"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                />
              </div>
            </div>
          </div>
          <ResonForChange
            v-if="takeReasonDialog"
            v-on:close="takeReasonDialog = false"
            v-on:reasonSubmit="deleteTestConfig"
            title="Reason for Archive"
          ></ResonForChange>
        </div>
      </el-col>
    </el-row>
  </section>
</template>
<script>
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import { mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";
const ResonForChange = defineAsyncComponent(() =>
  import("@/components/misc/ResonForChange")
);
import FilterHelper from "@/mixins/FilterHelper";
import bloodConstants from "@/config/constants/bloodConstants";
import { bloodSearchOptions } from "@/config/constants/searchConstants";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  mixins: [UserPermissionsHelper, FilterHelper],
  components: {
    ResonForChange,
  },

  data() {
    return {
      searchBy: "",
      searchOptions: bloodSearchOptions,
      search_string: "",
      previousSearchString: "",
      page: 1,
      loading: false,
      pageSize: 20,
      testData: {},
      total: 0,
      takeReasonDialog: false,
      requestArchiveConfig: "",
      orderBy: "",
      orderType: "",
      refresh: false,
      instruments: bloodConstants.BLOOD_INSTRUMENTS,
      search_instrument: "",
      search_status: "all",
      searchByKey: ["search_string"],
    };
  },
  computed: {
    ...mapGetters("bloodConfig", [
      "getAllInstrumentTestConfig",
      "getQcConfigDeleteStatus",
      "getIsActiveStatus",
    ]),
    ...mapGetters("errors", ["getErrors", "getError"]),
    isSearchByInput() {
      return this.searchByKey.indexOf(this.searchBy) != -1 ? true : false;
    },
  },

  async mounted() {
    await this.setQueryParams();
    await this.fetchBloodTestConfig(this.page);
  },

  methods: {
    clearInputSearch() {
      if (this.search_string || this.search_instrument) {
        this.search_string = "";
        this.search_instrument = "";
        this.isSearchByInput = "";
        this.page = 1;
        this.fetchBloodTestConfig(this.page);
      }
    },
    getAutoCompleteCases() {
      setTimeout(() => {
        this.page = 1;
        this.fetchBloodTestConfig(this.page);
      }, 600);
    },
    onChangeSort({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "asc" : "desc";
      } else {
        this.orderBy = "created_at";
        this.orderType = "DESC";
      }
      this.fetchBloodTestConfig(this.page);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchBloodTestConfig(this.page);
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchBloodTestConfig(this.page);
    },
    setQueryParams() {
      this.page = this.$route.query.selectedConfigPage;
      this.search_instrument = this.$route.query.selectedInstrument;
      this.search_string = this.$route.query.selectedConfigString;

      this.search_status = this.$route.query.selectedConfigStatus
        ? this.$route.query.selectedConfigStatus == "true"
          ? true
          : false
        : "all";

      if (this.$route.query.selectedConfigPageSize) {
        this.pageSize = parseInt(this.$route.query.selectedConfigPageSize);
      }
    },
    searchString() {
      setTimeout(() => {
        this.fetchBloodTestConfig(1);
      }, 500);
    },
    prepareParams(page) {
      let params = { page: page, limit: this.pageSize };

      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }

      if (this.search_string) {
        params.search_string = this.search_string.trim();
      }
      if (this.search_instrument) {
        params.instrument = this.search_instrument;
      }
      if (this.search_status != "all") {
        params.is_active = this.search_status;
      }
      return params;
    },

    async fetchBloodTestConfig(page, applyQuery = true) {
      this.page = page;
      this.loading = true;
      let params = this.prepareParams(page);
      await this.$store.dispatch("bloodConfig/fetchAllBloodTestConfig", params);

      this.testData = JSON.parse(
        JSON.stringify(this.getAllInstrumentTestConfig)
      );
      if (applyQuery) {
        this.updateQueryParams(params);
      } else {
        this.updateQueryParam(this.$route.name, {}, true);
      }
      this.loading = false;
    },
    updateQueryParams(params) {
      let selectedParams = {
        selectedConfigPage: params.page,
        selectedInstrument: params.instrument,
        selectedConfigStatus: params.is_active,

        selectedConfigPageSize: params.limit,
        selectedConfigString: params.search_string,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },

    onView(row) {
      let params = this.prepareParams();
      this.loading = true;
      this.$router.push({
        name: "Blood-Test-Config-Information-Update",
        params: { test_id: row._id },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
      this.loading = false;
    },
    onLevelView(row) {
      this.loading = true;
      this.$router.push({
        name: "All-Blood-Instrument-Test-Level",
        params: {
          test_id: row._id,
        },
        query: { assayName: row.assay_name, instrument: row.instrument },
      });
    },
    async createNewConfig() {
      this.$router.push({
        name: "Blood-Test-Config-Creation",
      });
    },

    async toggleActiveStatus(status, test) {
      try {
        this.$confirm(
          `Are you sure to ${status ? "Enable" : "Disable"} the test config `
        )
          .then(async () => {
            await this.toggleNotifyStatus(status, test._id);
          })
          .catch(async () => {
            this.loading = true;
            await this.fetchBloodTestConfig(this.page);
            this.loading = false;
          });
      } catch (err) {
        console.log(err);
      }
    },
    async toggleNotifyStatus(status, id) {
      try {
        this.loading = true;
        let params = {
          id,
          data: {
            is_active: status,
          },
        };
        await this.$store.dispatch("bloodConfig/toggleIsActiveStatus", params);
        if (this.getIsActiveStatus) {
          successNotification(
            `Is Active Status ${status ? "Enabled" : "Disabled"} successfully`
          );
          await this.fetchBloodTestConfig(this.page);
        } else if (this.getError) {
          errorNotification(this.getError || "Error in update");
          this.refresh = true;
          setTimeout(() => {
            this.refresh = false;
          }, 500);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    async deleteTestConfig(reason) {
      await this.$store.dispatch({
        config_id: this.requestArchiveConfig,
        reason: reason,
      });
    },
  },
};
</script>

<style lang="scss"></style>
