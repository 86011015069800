<template>
  <section
    id="add-bloodinstrument-test"
    class="case-creation"
    v-loading="loading"
  >
    <div class="container-small-table">
      <div class="case-creation__step case-creation__patient-information">
        <div class="case-creation__card">
          <div class="case-creation__heading">
            <div class="title-with-icon">
              <span class="icon">
                <img
                  src="@/assets/images/icons/icon-patient-info.svg"
                  alt="icon"
                  class="img-fluid"
                />
              </span>
              <h3 class="title">
                {{ `${getActionName} Blood Instrument Test Config` }}
              </h3>
            </div>
            <div
              class="edit-block"
              v-if="!editForm && showEditConfigInformation"
            >
              <el-button class="edit-btn" @click="configFormView">
                <span class="edit-icon">
                  <img
                    src="@/assets/images/icons/edit-case-information.svg"
                    alt=""
                  />
                  <p>EDIT</p>
                </span>
              </el-button>
            </div>
          </div>

          <div class="case-creation__body pb-0">
            <div class="vue-form" v-if="editForm && !loading">
              <el-form :model="bloodTestConfig" :rules="rules" ref="ruleForm">
                <el-row :gutter="30">
                  <el-col :xl="6" :lg="6" :md="12" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text"
                          >Instrument
                          <span class="star">*</span>
                        </label>
                        <el-select
                          placeholder="Select Status"
                          v-model="bloodTestConfig.instrument"
                          :filterable="true"
                          :clearable="true"
                          :filter-method="filterInstruments"
                          @focus="filterInstruments()"
                          default-first-option
                        >
                          <el-option
                            v-for="status in filteredInstruments"
                            :key="status.value"
                            :value="status.value"
                            :label="status.name"
                          ></el-option>
                        </el-select>
                        <p class="err" v-if="this.getErrors.instrument">
                          <FormError errorName="instrument"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="6" :lg="6" :md="12" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text"
                          >Assay Name Code
                          <span class="star">*</span>
                        </label>
                        <el-input
                          v-model="bloodTestConfig.assay_name"
                        ></el-input>
                        <p class="err" v-if="this.getErrors.assay_name">
                          <FormError errorName="assay_name"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :xl="6" :lg="6" :md="12" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text"
                          >Test Name
                          <span class="star">*</span>
                        </label>
                        <el-input v-model="bloodTestConfig.test_name">
                        </el-input>
                        <p class="err" v-if="this.getErrors.test_name">
                          <FormError errorName="test_name"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="6" :lg="6" :md="12" :sm="12" :xs="12">
                    <div
                      class="case-creation__actions"
                      v-if="editForm && !loading"
                    >
                      <ul class="list">
                        <li>
                          <el-button
                            type="default"
                            class="btn cancel-button"
                            @click="cancelCreation"
                            >Cancel</el-button
                          >
                        </li>
                        <li>
                          <el-button
                            type="success"
                            class="btn save-button"
                            @click="createTestConfig('ruleForm')"
                            :disabled="loadingProfile"
                            :loading="loadingProfile"
                          >
                            Save
                          </el-button>
                        </li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <div class="case-view-information" v-else-if="getqcConfigData">
              <el-row :gutter="30">
                <el-col :xl="8" :lg="8" :md="12" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Instrument</h4>
                    <p class="value">{{ getqcConfigData.instrument }}</p>
                  </div>
                </el-col>
                <el-col :xl="8" :lg="8" :md="12" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Assay Name Code</h4>
                    <p class="value">{{ getqcConfigData.assay_name }}</p>
                  </div>
                </el-col>
                <el-col :xl="8" :lg="8" :md="12" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Test Name</h4>
                    <p class="value">{{ getqcConfigData.test_name }}</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ResonForChange
      v-if="takeReasonDialog"
      v-on:close="takeReasonDialog = false"
      v-on:reasonSubmit="updateBloodTestConfig"
    ></ResonForChange>
  </section>
</template>

<script>
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import AppHelper from "@/mixins/AppHelper";
import { defineAsyncComponent } from "vue";
const FormError = defineAsyncComponent(() =>
  import("@/components/misc/FormError")
);
import bloodConstants from "@/config/constants/bloodConstants";

const ResonForChange = defineAsyncComponent(() =>
  import("@/components/misc/ResonForChange")
);
import { mapGetters } from "vuex";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  mixins: [UserPermissionsHelper, AppHelper],
  components: {
    ResonForChange,
    FormError,
  },
  name: "profile",
  computed: {
    ...mapGetters("bloodConfig", [
      "getSingleBloodQcConfig",
      "getQcConfigCreationStatus",
      "getQcConfigUpdateStatus",
    ]),
    ...mapGetters("errors", ["getErrors", "getError"]),
    getqcConfigData() {
      if (
        this.getSingleBloodQcConfig &&
        Object.keys(this.getSingleBloodQcConfig).length > 0 &&
        this.getSingleBloodQcConfig
      ) {
        return this.getSingleBloodQcConfig;
      }
      return null;
    },
    getActionName() {
      if (this.$route.name == "Blood-Test-Config-Creation") {
        return "Add";
      } else if (this.editForm) {
        return "Edit";
      } else {
        return "View";
      }
    },
  },

  data() {
    return {
      instruments: bloodConstants.BLOOD_INSTRUMENTS,
      filteredInstruments: [],
      loading: false,
      loadingProfile: false,
      showEditConfigInformation: false,
      bloodTestConfig: {
        test_name: "",
        instrument: "",
        assay_name: "",
      },
      editForm: false,
      takeReasonDialog: false,
      test_id: "",
      rules: {
        name: [
          {
            required: true,
            message: "Assay Name is Required",
            trigger: "blur",
          },
          {
            trigger: "blur",
          },
        ],
        test_name: [
          { required: true, message: "Test Name is Required", trigger: "blur" },
        ],
        instrument_name: [
          {
            required: true,
            message: "Instrument Name is Required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  async mounted() {
    this.$store.commit("errors/clear", null, { root: true });
    this.test_id = this.$route.params.test_id;
    if (this.$route.params.test_id) {
      this.editForm = false;
      this.showEditConfigInformation = true;
      this.getBloodConfigData();
    } else {
      this.editForm = true;
      this.showEditConfigInformation = false;
    }
    this.filterInstruments();
  },
  methods: {
    filterInstruments(filterValue) {
      if (filterValue && filterValue.length) {
        this.filteredInstruments = [...this.instruments].filter(
          (option) =>
            option.name.toLowerCase().indexOf(filterValue.toLowerCase()) == 0
        );
      } else {
        this.filteredInstruments = this.instruments;
      }
      this.sortInstruments();
    },
    sortInstruments() {
      this.filteredInstruments.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    configFormView() {
      this.editForm = true;
      this.setBloodQcConfigData();
      this.showEditConfigInformation = false;
    },
    setBloodQcConfigData() {
      if (this.getSingleBloodQcConfig) {
        this.bloodTestConfig = this.getSingleBloodQcConfig;
      }
    },
    async getBloodConfigData() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "bloodConfig/fetchSingleTestConfig",
          this.test_id
        );
        this.setBloodQcConfigData();
        this.showEditConfigInformation = true;
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    prepareParams() {
      let params = {
        test_name: this.bloodTestConfig.test_name,
        assay_name: this.bloodTestConfig.assay_name,
        instrument: this.bloodTestConfig.instrument,
      };

      return this.getFormattedValues(params);
    },
    createTestConfig() {
      let params = this.prepareParams();
      params.test_id = this.$route.params.test_id;
      if (params.test_id) {
        this.updateBloodTestConfig();
        // this.askReason();
      } else {
        this.addTestConfig(params);
      }
    },
    async addTestConfig(params) {
      this.loadingProfile = true;
      await this.$store.dispatch("bloodConfig/createBloodTestConfig", params);
      this.loadingProfile = false;
      if (this.getQcConfigCreationStatus) {
        successNotification("Blood Test Config Created Successfully");
        this.goToInstrumentTests();
      } else if (!this.getErrors) {
        errorNotification(this.getError || "Error in adding Blood Test Config");
      }
    },
    async updateBloodTestConfig() {
      try {
        this.loadingProfile = true;
        let params = this.prepareParams();
        params.id = this.$route.params.test_id;

        await this.$store.dispatch("bloodConfig/updateBloodTestConfig", params);
        this.loadingProfile = false;
        if (this.getQcConfigUpdateStatus) {
          successNotification("Blood Config Updated Successfully");
          this.goToInstrumentTests();
        } else if (!this.getErrors) {
          errorNotification(
            this.getError || "Error in updating Blood Test Config"
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async askReason() {
      this.takeReasonDialog = true;
    },
    cancelCreation() {
      if (this.$route.params.test_id) {
        this.editForm = false;
        this.showEditConfigInformation = true;
      } else {
        this.goToInstrumentTests();
      }
    },
    goToInstrumentTests() {
      this.$router.push({
        name: "AllInstrumentTestConfig",
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
  },
};
</script>

<style lang="scss">
#add-bloodinstrument-test {
  .case-creation__heading .title-with-icon {
    width: 100%;
    max-width: 315px;
  }
  .edit-block {
    .edit-btn {
      border: none;
      padding: 0;
      min-height: 30px;
      .edit-icon {
        display: flex;
        align-items: center;
        margin-right: 10px;
        color: #111111;

        img {
          margin-right: 6px;
          width: 18px;
          margin-bottom: 3px;
          height: 18px;
        }
      }
      &:hover {
        background-color: transparent !important;
        color: #111111;
      }
    }
  }
}
</style>
