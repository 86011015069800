<template>
  <div
    :id="containerId"
    :style="'min-height:400px;width:' + width || '100%'"
    :ref="containerId"
  ></div>
</template>

<script>
import ChartHelper from "@/mixins/ChartsHelper";
import Highcharts from "@/config/highcharts";

window.qc1Chart = null;
export default {
  mixins: [ChartHelper],
  props: ["containerId", "chartData", "width", "chartTitle"],
  data() {
    return {
      loading: false,
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      bloodChartData: {
        chart: {
          zoomType: "x",
        },
        scrollbar: {
          enabled: true,
          height: 10,
          showFull: false,
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
          labelFormat: "{name}",
          useHTML: true,
          width: "8%",
          marginTop: 5,
          borderWidth: 2,
          padding: 5,
          itemStyle: {
            color: "#000000",
            fontSize: "13px",
          },
        },
        plotOptions: {
          series: {
            events: {
              legendItemClick: function (e) {
                e.preventDefault();
              },
            },
          },
        },
        title: {
          text: "6-MAM 1 -- QC1",
        },
        subtitle: {
          text: " Lot 1",
        },
        decimals: true,
        credits: {
          enabled: false,
        },

        yAxis: [
          {
            title: {
              text: "Concentration (ng/mL)",
            },
            allowDecimals: false,
            lineWidth: 2,
            gridLineWidth: 0,
            tickWidth: 2,
            min: 0,
            showEmpty: true,
            max: 40,
            tickInterval: 2,
            plotLines: [
              {
                color: "#AA5A5D", // Color value
                dashStyle: "Solid", // Style of the plot line. Default to solid
                value: 20, // Value of where the line will appear
                width: 1, // Width of the line
              },
              {
                color: "#71D273", // Color value
                dashStyle: "Solid", // Style of the plot line. Default to solid
                value: 12, // Value of where the line will appear
                width: 1, // Width of the line
              },

              {
                color: "#AA5A5D", // Color value
                dashStyle: "Solid", // Style of the plot line. Default to solid
                value: 8.3, // Value of where the line will appear
                width: 1, // Width of the line
              },
            ],
          },
          {
            title: {
              text: "Acceptance Criteria",
            },
            categories: ["-25%"],
            gridLineWidth: 0,
            offset: 0,
            lineWidth: 2,
            // height: '75%',
            opposite: true,
            // max: 8,
          },
        ],
        xAxis: {
          categories: [],
          tickWidth: 2,
          tickmarkPlacement: "on",
          minPadding: 10,
          labels: {
            rotation: 290,
            offset: 100,
            padding: 30,
          },
          events: {
            afterSetExtremes: () => {},
          },
        },

        tooltip: {
          pointFormat: "X - {point.category} <br/>",
          footerFormat: "Y - {point.y} <br/>",
        },
        series: [
          {
            legendIndex: 1,
            pointWidth: 100,
            groupPadding: 15,
            type: "scatter",
            name: "Used",
            data: [],
            marker: {
              enabled: true,
              fillColor: "black",
              radius: 4,
              symbol: "triangle",
            },
            zones: [
              {
                value: 15,
                upto: "lower",
                className: "zone-mean",
                color: "#FF0000",
              },
              {
                value: 20,
                upto: "upper",
                color: "#000",
              },
              {
                color: "#FF0000",
              },
            ],
          },
          {
            name: "Unused",
            color: "red",
            type: "scatter",
            data: [],
            marker: {
              enabled: true,
              symbol: "cross",
              radius: 4,
              lineColor: "red",
              lineWidth: 2,
            },
            legendIndex: 2,
          },
          {
            name: "2SD",
            legendIndex: 3,
            color: "#AA5A5D",
            marker: {
              symbol: "cross",
              lineColor: "#AA5A5D",
            },
          },
          {
            name: "Mean",
            legendIndex: 4,
            color: "#71D273",
            marker: {
              symbol: "cross",
              lineColor: "#71D273",
            },
          },
        ],
      },
    };
  },
  async mounted() {
    this.prepareBloodChart();
  },
  methods: {
    prepareBloodChart() {
      this.loading = true;
      if (this.chartData) {
        this.bloodChartData.xAxis.categories = this.chartData.data.map(
          (e) => e.date
        );

        // getting data lower values
        let upper = this.chartData.mean + this.chartData["2sd"];
        let lower = this.chartData.mean - this.chartData["2sd"];

        // preparing map lower values
        let min = this.getMinValue(lower, this.chartData.data, "conc");
        let max = this.getMaxValue(upper, this.chartData.data, "conc");

        this.bloodChartData.yAxis[0].min = parseInt(min) - 1;
        this.bloodChartData.yAxis[0].max = parseInt(max) + 1;
        this.bloodChartData.yAxis[0].tickInterval = 10;

        this.bloodChartData.yAxis[0].plotLines[0].value = upper;
        this.bloodChartData.yAxis[0].plotLines[1].value = this.chartData.mean;
        this.bloodChartData.yAxis[0].plotLines[2].value = lower;

        // set graph data
        this.bloodChartData.series[0].data = [];
        this.chartData.data.forEach((element, index) => {
          const value = parseFloat(element.conc);
          let markerValueData = [index, value];
          let i = 1;
          if (value >= lower && value <= upper) {
            // unused
            i = 0;
          }
          this.bloodChartData.series[i].data.push(markerValueData);
        });

        this.bloodChartData.title.text = `${this.chartTitle.test} - ${this.chartTitle.level}`;
        this.bloodChartData.subtitle.text = `${this.chartData.lot}`;
        window.qc1Chart = Highcharts.chart(
          this.containerId,
          this.bloodChartData
        );
      }
      this.loading = false;
    },
  },
};
</script>
