<template>
  <section class="all-cases-view">
    <div class="container-default">
      <div class="listings-navbar">
        <el-row>
          <el-col :lg="7">
            <ul>
              <li>
                <el-button
                  class="btn button-with-icon"
                  @click="createInstrumentTestLevel"
                >
                  <span class="mr-8">
                    <img
                      src="@/assets/images/icons/plus-icon.svg"
                      alt="icon"
                      class="inactive"
                    />
                    <img
                      src="@/assets/images/icons/plus-icon-white.svg"
                      alt="icon"
                      class="active"
                    />
                  </span>
                  <span>ADD BLOOD INSTRUMENT TEST LEVEL</span>
                </el-button>
              </li>
            </ul>
          </el-col>
          <el-col :lg="17">
            <ul
              class="d-flex flex-wrap justify-content-end list-items-group-15"
            >
              <li>
                <div class="form-element input-with-icon search-input-lg">
                  <el-input
                    placeholder="Search Lot Name"
                    v-model="search_string"
                    @input="getAutoCompleteCases()"
                    :clearable="true"
                  ></el-input>
                  <span>
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid icon"
                    />
                  </span>
                </div>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
      <div class="primary-content-view-new">
        <div
          class="listings listings__table listings__all-cases"
          id="all-cases-listings"
        >
          <el-table :data="testData.data" v-loading="loading">
            <el-table-column
              label="LEVEL NAME"
              prop="level_name"
              min-width="100"
              sortable="custom"
            >
              <template v-slot="scope">
                {{
                  scope.row.qc_level_id && scope.row.qc_level_id.level_name
                    ? scope.row.qc_level_id.level_name
                    : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="LOT NAME"
              prop="lot_name"
              min-width="100"
              sortable="custom"
            >
              <template v-slot="scope">{{
                scope.row ? scope.row.lot_name : "--"
              }}</template>
            </el-table-column>
            <el-table-column
              label="NAME FOR BARCODE"
              prop="name_for_barcode"
              min-width="100"
              sortable="custom"
            >
              <template v-slot="scope">{{
                scope.row ? scope.row.name_for_barcode : "--"
              }}</template>
            </el-table-column>
            <el-table-column
              label="EXPIRES AT"
              prop="expires_at"
              min-width="100"
              sortable="custom"
            >
              <template #default="scope">
                <div v-if="scope.row.expires_at" class="d-flex">
                  <div class="date pr-10">
                    {{ momentWithTimezone(scope.row.expires_at, "MM-DD-yyyy") }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="MEAN"
              prop="mean"
              min-width="100"
              sortable="custom"
            >
              <template v-slot="scope">
                {{ scope.row ? scope.row.mean : "--" }}</template
              >
            </el-table-column>
            <el-table-column
              label="SD"
              prop="sd"
              min-width="100"
              sortable="custom"
            >
              <template v-slot="scope">{{
                scope.row ? scope.row.sd : "--"
              }}</template>
            </el-table-column>
            <el-table-column
              label="CREATED AT"
              prop="created_at"
              min-width="100"
              sortable="custom"
            >
              <template #default="scope">
                <div v-if="scope.row.created_at" class="d-flex">
                  <div class="date pr-10">
                    {{ momentWithTimezone(scope.row.created_at, "MM-DD-yyyy") }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="action"
              label="Actions"
              width="140"
              fixed="right"
            >
              <template v-slot="scope">
                <ul class="listings__action-buttons">
                  <li @click="onView(scope.row)">
                    <el-button class="view-button">
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/view-icon.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/view-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
          <div class="listings listings__pagination">
            <el-pagination
              v-model:currentPage="page"
              v-model:page-size="pageSize"
              :page-sizes="[10, 25, 50, 100]"
              :small="small"
              :disabled="disabled"
              :background="background"
              layout=" prev, pager, next, jumper, sizes ,total"
              :total="testData.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import { mapGetters } from "vuex";
import FilterHelper from "@/mixins/FilterHelper";
import bloodConstants from "@/config/constants/bloodConstants";

import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  mixins: [UserPermissionsHelper, FilterHelper],
  components: {},

  data() {
    return {
      page: 1,
      loading: false,
      pageSize: 10,
      testData: {},
      total: 0,
      orderBy: "",
      orderType: "",
      refresh: false,
      search_status: "all",
      search_string: "",
    };
  },
  computed: {
    ...mapGetters("bloodTestLevels", [
      "getAllBloodInstrumentTestLevels",
      "getLevelActiveStatus",
    ]),

    testId() {
      return this.$route.params.test_id;
    },
  },

  async mounted() {
    this.setQueryParams();
    await this.fetchBloodInstrumentLevels(this.page);
  },

  methods: {
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchBloodInstrumentLevels(this.page);
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchBloodInstrumentLevels(this.page);
    },
    setQueryParams() {
      this.page = this.$route.query.selectedLevelPage;
      this.search_status = this.$route.query.selectedLevelStatus
        ? this.$route.query.selectedLevelStatus == "true"
          ? true
          : false
        : "all";
      if (this.$route.query.selectedLevelPageSize) {
        this.pageSize = parseInt(this.$route.query.selectedLevelPageSize);
      }
      if (this.$route.query.selectedLevelSearchString) {
        this.search_string = this.$route.query.selectedLevelSearchString;
      }
    },

    prepareParams(page) {
      let params = { page: page, limit: this.pageSize };

      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }

      if (this.search_string) {
        params.search_string = this.search_string.trim();
      }
      if (this.search_status != "all") {
        params.is_active = this.search_status;
      }

      return params;
    },

    async fetchBloodInstrumentLevels(page, applyQuery = true) {
      this.page = page;
      this.loading = true;
      let params = this.prepareParams(page);
      params.id = this.testId;

      await this.$store.dispatch(
        "bloodTestLevels/fetchBloodInstrumentLevels",
        params
      );

      this.testData = JSON.parse(
        JSON.stringify(this.getAllBloodInstrumentTestLevels)
      );
      if (applyQuery) {
        this.updateQueryParams(params);
      } else {
        this.updateQueryParam(this.$route.name, {}, true);
      }
      this.loading = false;
    },
    updateQueryParams(params) {
      let selectedParams = {
        selectedLevelPage: params.page,
        selectedLevelStatus: params.is_active,
        selectedLevelPageSize: params.limit,
        selectedLevelSearchString: params.search_string,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },

    getAutoCompleteCases() {
      setTimeout(() => {
        this.page = 1;
        this.fetchBloodInstrumentLevels(1);
      }, 600);
    },

    async onView(row) {
      let params = this.prepareParams();
      this.loading = true;
      this.$router.push({
        name: "Update-Blood-Instrument-Test-Level",
        params: {
          level_id: row._id,
          test_id: this.testId,
        },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
      this.loading = false;
    },

    async createInstrumentTestLevel() {
      this.$router.push({
        name: "Add-Blood-Instrument-Test-Level",
      });
    },
  },
};
</script>

<style lang="scss">
.all-cases-view {
  .inner-navbar {
    .inner-navbar-buttons-alignment {
      margin: 0 !important;

      .input-field {
        height: 35px;
      }
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        li {
          &:not(:last-child) {
            margin-right: 1em;
          }
          .add-btn {
            padding: 12px 15px;
            background: #6f52ed;
            border-radius: 3px;
            color: #fff;
            border: none;
            font-weight: 500;
            text-transform: uppercase;
            img {
              margin-right: 10px;
              margin-bottom: -3px;
            }
          }
        }
      }
    }
  }
  table {
    thead {
      tr {
        th:first-child {
          .cell {
            padding-left: 1em !important;
          }
        }
      }
    }
    tbody {
      tr {
        td:first-child {
          .cell {
            a {
              color: #1349a5 !important;
              padding-left: 1em;
            }
          }
        }
      }
    }
  }

  .content {
    margin-top: 20px;
    background: #fff;
  }
}
#batchesList {
  padding: 0em 0em 0.5em;
  .filters {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
  }
  .empty-batch {
    margin: 15% auto 0;
    max-width: 300px;
    text-align: center;
    .circle-icon {
      width: 80px;
      height: 80px;
      background: #ffffff;
      border-radius: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1em;
      img {
        max-width: 34px;
      }
    }
  }
  .main-table-view {
    margin-top: 1em;
  }
}
</style>
