<template>
  <section class="all-cases-view">
    <div class="container-small-table">
      <div class="listings-navbar">
        <el-row type="flex" justify="center">
          <el-col :lg="7">
            <ul class="d-flex list-items-group-15">
              <li>
                <el-button
                  class="btn button-with-icon"
                  @click="centerDialogVisible = true"
                >
                  <span class="mr-8">
                    <img
                      src="@/assets/images/icons/plus-icon.svg"
                      alt="icon"
                      class="inactive"
                    />
                    <img
                      src="@/assets/images/icons/plus-icon-white.svg"
                      alt="icon"
                      class="active"
                    />
                  </span>
                  <span>Add Blood QC Level</span>
                </el-button>
              </li>
            </ul>
          </el-col>
          <el-col :lg="17">
            <ul
              class="d-flex flex-wrap justify-content-end list-items-group-15"
            >
              <li>
                <div class="form-element search-with-filter-input">
                  <el-select
                    placeholder="Select Status"
                    v-model="search_status"
                    :filterable="true"
                    @change="fetchAllBloodQcLevels(1)"
                    default-first-option
                  >
                    <el-option
                      v-for="status in filterStatus"
                      :key="status.value"
                      :value="status.value"
                      :label="status.name"
                    ></el-option>
                  </el-select>
                  <span class="search-icon">
                    <img
                      src="@/assets/images/icons/search-icon-with-bg-red.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </span>
                </div>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>

      <div class="primary-content-view-new" v-loading="loading">
        <div
          class="listings listings__table listings__all-cases"
          id="all-cases-listings"
        >
          <el-table
            :data="allBloodQcLevel.data"
            :default-sort="{
              prop: orderBy,
              order: orderType == 'asc' ? 'ascending' : 'descending',
            }"
            @sort-change="onChangeSort"
          >
            <el-table-column
              label="Level Name"
              min-width="300"
              prop="level_name"
              sortable="custom"
            >
              <template v-slot="scope">
                <span
                  v-if="isEditQCLevel(scope.row)"
                  class="d-flex text-center"
                >
                  <div class="blood-qc-level-input">
                    <el-input
                      size="mini"
                      placeholder="Enter Blood QC Level"
                      v-model="level_name"
                    >
                    </el-input>
                    <FormError error-name="level_name"></FormError>
                  </div>

                  <div class="ml-10 d-flex space-between actions-btns">
                    <el-button @click="editCancel" size="mini"
                      >CANCEL</el-button
                    >

                    <el-button type="success" @click="submitEdit()" size="mini"
                      >UPDATE</el-button
                    >
                  </div>
                </span>
                <span v-else>
                  {{ scope.row.level_name }}
                  <i
                    @click="setEdit(scope.row)"
                    class="el-icon-edit pl-10"
                    v-if="false"
                  ></i>
                </span>
              </template>
            </el-table-column>

            <el-table-column label="Active" width="100">
              <template v-slot="{ row }">
                <div class="ml-20">
                  <el-switch
                    v-model="row.is_active"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    @change="toggleActiveStatus($event, row)"
                  >
                  </el-switch>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="action"
              v-if="false"
              label="Actions"
              width="100"
            >
              <template v-slot="scope">
                <ul class="action-buttons justify-center">
                  <li>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Archive"
                      placement="top"
                    >
                      <el-button
                        class="delete-button"
                        @click="archiveLevel(scope.row._id)"
                      >
                        <feather type="archive" class="icon-md"></feather>
                      </el-button>
                    </el-tooltip>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
          <div class="listings listings__pagination">
            <el-pagination
              v-model:currentPage="page"
              v-model:page-size="pageSize"
              :page-sizes="[10, 25, 50, 100]"
              :small="small"
              :disabled="disabled"
              :background="background"
              layout=" prev, pager, next, jumper, sizes ,total"
              :total="allBloodQcLevel.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
      <!--  Model popup -->
      <div class="default-modal">
        <el-dialog
          top="10%"
          width="30%"
          v-model="centerDialogVisible"
          :before-close="handleLevelDialogClose"
        >
          <div class="dialog-header">
            <span class="header-part">
              <img
                src="@/assets/images/icons/plus-icon.svg"
                alt="icon"
                class="inactive"
              />
              Add Blood QC Level
            </span>
            <el-button
              size="medium"
              class="el-white"
              type="info"
              plain
              @click="centerDialogVisible = false"
            >
              <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
            /></el-button>
          </div>
          <el-row :gutter="20" class="popup-text-area pb-0">
            <el-col :span="24">
              <el-form ref="ruleForm" class="demo-ruleForm">
                <el-form-item class="mb-0">
                  <label for="text">Level Name</label>

                  <el-input type="name" v-model="level_name"></el-input>
                  <p class="err" v-if="getErrors && getErrors.title">
                    <FormError errorName="level_name"></FormError>
                  </p>
                </el-form-item>
                <div v-if="Reason">
                  <p class="err">Reason must be 5 characters</p>
                </div>
              </el-form>
            </el-col>
            <el-col :span="24">
              <div class="dialog-footer">
                <el-button
                  class="blue-save-button"
                  :disabled="disableQcLevel"
                  @click="createBloodQcLevel"
                  :loading="sectionLoading"
                  >Save</el-button
                >
              </div>
            </el-col>
          </el-row>
        </el-dialog>
      </div>
    </div>
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
import bloodConstants from "@/config/constants/bloodConstants";
import FilterHelper from "@/mixins/FilterHelper";
const FormError = defineAsyncComponent(() =>
  import("@/components/misc/FormError")
);

import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  mixins: [FilterHelper],
  components: { FormError },
  data() {
    return {
      page: 1,
      pageSize: 25,
      centerDialogVisible: false,
      loading: false,
      switchDisable: false,
      sectionLoading: false,
      input: "",

      level_name: "",
      is_active: "",
      allBloodQcLevel: {},
      selectedLevelForEdit: {},
      search_status: "all",
      filterStatus: bloodConstants.FILTER_STATUS,
      orderBy: "created_at",
      orderType: "desc",
    };
  },
  computed: {
    ...mapGetters("bloodQcLevels", [
      "getAllBloodQcLevels",
      "getCreateBloodQcLevels",
      "getUpdateQcLevelStatus",
      "getArchiveStatus",
    ]),
    ...mapGetters("error", ["getError", "getErrors"]),
    disableQcLevel() {
      if (this.level_name && this.level_name.length >= 5) {
        return false;
      }
      return true;
    },

    Reason() {
      if (this.level_name && this.level_name.length >= 3) {
        return true;
      }

      return false;
    },
  },
  async mounted() {
    await this.setQueryParams();
    await this.fetchAllBloodQcLevels(this.page);
  },

  methods: {
    onChangeSort({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "asc" : "desc";
      } else {
        this.orderBy = "created_at";
        this.orderType = "DESC";
      }
      this.fetchAllBloodQcLevels(this.page);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchAllBloodQcLevels(this.page);
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchAllBloodQcLevels(this.page);
    },
    setQueryParams() {
      this.page = this.$route.query.selectedBloodQcPage
        ? parseInt(this.$route.query.selectedBloodQcPage)
        : 1;
      this.search_status = this.$route.query.selectedQcStatus
        ? this.$route.query.selectedQcStatus == "true"
          ? true
          : false
        : "all";

      this.pageSize = this.$route.query.selectedBloodQcPageSize
        ? parseInt(this.$route.query.selectedBloodQcPageSize)
        : 25;
    },
    prepareParams(page) {
      let params = { page: page, limit: this.pageSize };

      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }
      if (this.search_status != "all") {
        params.is_active = this.search_status;
      }
      return params;
    },
    async fetchAllBloodQcLevels(page, applyQuery = true) {
      this.page = page;
      this.loading = true;
      let params = this.prepareParams(page);
      await this.$store.dispatch(
        "bloodQcLevels/fetchAllBloodQcLevelsData",
        params
      );
      this.allBloodQcLevel = JSON.parse(
        JSON.stringify(this.getAllBloodQcLevels)
      );
      if (applyQuery) {
        this.updateQueryParams(params);
      } else {
        this.updateQueryParam(this.$route.name, {}, true);
      }
      this.loading = false;
    },
    updateQueryParams(params) {
      let selectedParams = {
        selectedBloodQcPage: params.page || 1,
        selectedQcStatus: params.is_active,
        selectedBloodQcPageSize: params.limit || 10,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },

    async createBloodQcLevel() {
      let params = {
        level_name: this.level_name,
      };
      this.sectionLoading = true;
      await this.$store.dispatch(
        "bloodQcLevels/createBloodQcLevelsData",
        params
      );
      this.sectionLoading = false;
      if (this.getCreateBloodQcLevels) {
        successNotification("Blood QC Test Level Added Successfully");
        this.centerDialogVisible = false;
        this.level_name = "";
        this.fetchAllBloodQcLevels(this.page);
      } else if (!this.getErrors) {
        errorNotification(this.getError || "Error in creating Level");
      }
    },

    isEditQCLevel(row) {
      return (
        this.selectedLevelForEdit && this.selectedLevelForEdit._id == row._id
      );
    },
    setEdit(row) {
      this.switchDisable = true;
      this.selectedLevelForEdit = row;
      this.level_name = row.level_name;
      this.is_active = row.is_active;
    },
    editCancel() {
      this.switchDisable = false;
      this.selectedLevelForEdit = {};
      this.level_name = "";
      this.$store.commit("errors/clear", null, { root: true });
    },
    async submitEdit() {
      try {
        let params = {
          level_name: this.level_name,
          is_active: this.is_active,
          level_id: this.selectedLevelForEdit._id,
        };
        this.loading = true;
        await this.$store.dispatch("bloodQcLevels/updateQcLevel", params);
        this.loading = false;
        if (this.getUpdateQcLevelStatus) {
          successNotification("Blood QC Test Level Updated Successfully");
          this.selectedLevelForEdit = {};
          this.switchDisable = false;
          this.fetchAllBloodQcLevels(this.page);
        } else if (!this.getErrors) {
          errorNotification(this.getError || "Error in updating Level");
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async toggleActiveStatus(status, test) {
      try {
        this.$confirm(
          `Are you sure to ${status ? "Enable" : "Disable"} the Qc Level `
        )
          .then(async () => {
            await this.toggleNotifyStatus(status, test._id, test.level_name);
          })
          .catch(async () => {
            this.loading = true;
            await this.fetchAllBloodQcLevels(this.page);
            this.loading = false;
          });
      } catch (err) {
        console.log(err);
      }
    },
    async toggleNotifyStatus(status, id, levelName) {
      try {
        this.loading = true;
        let params = {
          level_id: id,
          is_active: status,
          level_name: levelName,
        };
        await this.$store.dispatch("bloodQcLevels/updateQcLevel", params);
        if (this.getUpdateQcLevelStatus) {
          successNotification(
            `Level Active Status ${
              status ? "Enabled" : "Disabled"
            } successfully`
          );
          await this.fetchAllBloodQcLevels(this.page);
        } else if (!this.getErrors) {
          errorNotification(this.getError || "Error in update");
          this.refresh = true;
          setTimeout(() => {
            this.refresh = false;
          }, 500);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    archiveLevel(levelId) {
      this.$confirm("Are you sure want to DELETE Level. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async () => {
          await this.deleteLevel(levelId);
        })
        .catch(() => {});
    },
    async deleteLevel(levelId) {
      this.loading = true;
      await this.$store.dispatch("bloodQcLevels/archiveLevel", { id: levelId });
      this.loading = false;
      if (this.getArchiveStatus) {
        successNotification("Blood QC Test Level Archived Successfully");
        this.fetchAllBloodQcLevels(this.page);
      } else if (!this.getErrors) {
        errorNotification(this.getError || "Error in update");
      }
    },
    handleLevelDialogClose() {
      this.centerDialogVisible = false;
      this.$store.commit("errors/clear", null, { root: true });
    },
  },
};
</script>

<style lang="scss"></style>
