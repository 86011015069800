<template>
  <section
    id="add-bloodinstrument-test-level"
    class="case-creation"
    v-loading="loading"
  >
    <div class="container-small-table">
      <div class="case-creation__step case-creation__patient-information">
        <div class="case-creation__card">
          <div class="case-creation__heading">
            <div class="title-with-icon">
              <span class="icon">
                <img
                  src="@/assets/images/icons/icon-patient-info.svg"
                  alt="icon"
                  class="img-fluid"
                />
              </span>
              <h3 class="title">
                {{ `${getActionName} Blood Instrument Test Level` }}
              </h3>
            </div>
            <div
              class="edit-block"
              v-if="!editForm && showEditConfigInformation"
            >
              <el-button class="edit-btn" @click="configFormView">
                <span class="edit-icon">
                  <img
                    src="@/assets/images/icons/edit-case-information.svg"
                    alt=""
                  />
                  <p>EDIT</p>
                </span>
              </el-button>
            </div>
          </div>
          <div class="case-creation__body pb-0">
            <div class="vue-form" v-if="editForm && !loading">
              <el-form
                :model="bloodInstrumentLevel"
                :rules="rules"
                ref="ruleForm"
              >
                <el-row :gutter="30">
                  <el-col :xl="4" :lg="8" :md="12" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text"
                          >QC Level ID <span class="star">*</span>
                        </label>
                        <el-select
                          v-model="bloodInstrumentLevel.qc_level_id"
                          placeholder="Please Select QC Level"
                          :filterable="true"
                          :clearable="true"
                          default-first-option
                        >
                          <el-option
                            v-for="status in getAllBloodQcLevels.data"
                            :key="status._id"
                            :value="status._id"
                            :label="status.level_name"
                          ></el-option>
                        </el-select>
                        <p class="err">
                          <FormError errorName="qc_level_id"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :xl="4" :lg="8" :md="12" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text"
                          >Lot Name
                          <span class="star">*</span>
                        </label>
                        <el-input
                          placeholder="Enter Lot Name"
                          v-model="bloodInstrumentLevel.lot_name"
                        ></el-input>
                        <p class="err">
                          <FormError errorName="lot_name"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="4" :lg="8" :md="12" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text"
                          >Name For Barcode
                          <span class="star">*</span>
                        </label>
                        <el-input
                          placeholder="Enter Name For Barcode"
                          v-model="bloodInstrumentLevel.name_for_barcode"
                        ></el-input>
                        <p class="err">
                          <FormError errorName="name_for_barcode"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="4" :lg="8" :md="12" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text"
                          >Expire At
                          <span class="star">*</span>
                        </label>
                        <el-date-picker
                          type="date"
                          placeholder="Pick a date"
                          v-model="bloodInstrumentLevel.expires_at"
                          format="MM-DD-YYYY"
                          value-format="YYYY-MM-DD"
                          :disabled-date="disabledDate"
                        ></el-date-picker>
                        <p class="err">
                          <FormError errorName="expires_at"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="4" :lg="8" :md="12" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text"
                          >Mean
                          <span class="star">*</span>
                        </label>

                        <el-input
                          type="number"
                          placeholder="Enter Mean"
                          v-model="bloodInstrumentLevel.mean"
                        ></el-input>
                        <p class="err">
                          <FormError errorName="mean"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="4" :lg="8" :md="12" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text"
                          >SD
                          <span class="star">*</span>
                        </label>
                        <el-input
                          placeholder="Enter SD"
                          type="number"
                          v-model="bloodInstrumentLevel.sd"
                        ></el-input>
                        <p class="err">
                          <FormError errorName="sd"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <div class="case-view-information" v-else-if="getSingleLevelData">
              <el-row :gutter="30">
                <el-col :xl="4" :lg="8" :md="12" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">QC Level Id</h4>
                    <p class="value">
                      {{ getSingleLevelData.qc_level_id.level_name }}
                    </p>
                  </div>
                </el-col>
                <el-col :xl="4" :lg="8" :md="12" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Lot Name</h4>
                    <p class="value">{{ getSingleLevelData.lot_name }}</p>
                  </div>
                </el-col>

                <el-col :xl="4" :lg="8" :md="12" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Name For Barcode</h4>
                    <p class="value">
                      {{ getSingleLevelData.name_for_barcode }}
                    </p>
                  </div>
                </el-col>
                <el-col :xl="4" :lg="8" :md="12" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Expire At</h4>
                    <div class="value">
                      {{
                        momentWithTimezone(
                          getSingleLevelData.expires_at,
                          "MM-DD-yyyy"
                        )
                      }}
                    </div>
                  </div>
                </el-col>
                <el-col :xl="4" :lg="8" :md="12" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Mean</h4>
                    <p class="value">{{ getSingleLevelData.mean }}</p>
                  </div>
                </el-col>

                <el-col :xl="4" :lg="8" :md="12" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">SD</h4>
                    <p class="value">{{ getSingleLevelData.sd }}</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="case-creation__actions" v-if="editForm && !loading">
          <ul class="list">
            <li>
              <el-button
                type="default"
                class="btn cancel-button"
                @click="cancelCreation"
                >Cancel</el-button
              >
            </li>
            <li>
              <el-button
                type="success"
                class="btn save-button"
                @click="saveTestLevel"
                :loading="buttonLoading"
              >
                Save
              </el-button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <ResonForChange
      v-if="takeReasonDialog"
      v-on:close="takeReasonDialog = false"
      v-on:reasonSubmit="updateBloodTesLevel"
    ></ResonForChange>
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
const FormError = defineAsyncComponent(() =>
  import("@/components/misc/FormError")
);
import AppHelper from "@/mixins/AppHelper";

const ResonForChange = defineAsyncComponent(() =>
  import("@/components/misc/ResonForChange")
);
import { mapGetters } from "vuex";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  mixins: [UserPermissionsHelper, AppHelper],
  components: {
    FormError,
    ResonForChange,
  },
  name: "profile",
  computed: {
    ...mapGetters("bloodTestLevels", [
      "getSingleBloodInstrumentTestLevel",

      "getCreateTestLevelStatus",
      "getUpdateTestLevelStatus",
    ]),
    ...mapGetters("bloodQcLevels", ["getAllBloodQcLevels"]),
    ...mapGetters("errors", ["getErrors", "getError"]),

    getSingleLevelData() {
      if (
        this.getSingleBloodInstrumentTestLevel &&
        Object.keys(this.getSingleBloodInstrumentTestLevel.data).length > 0 &&
        this.getSingleBloodInstrumentTestLevel.data
      ) {
        return this.getSingleBloodInstrumentTestLevel.data;
      }
      return null;
    },
    levelId() {
      if (this.$route.params.level_id) {
        return this.$route.params.level_id;
      }
      return null;
    },
    getActionName() {
      if (this.$route.name == "Add-Blood-Instrument-Test-Level") {
        return "Add";
      } else if (this.editForm) {
        return "Edit";
      } else {
        return "View";
      }
    },
  },

  data() {
    return {
      loading: false,
      buttonLoading: false,
      takeReasonDialog: false,
      showEditConfigInformation: false,
      disabledDate: (time) => {
        return time.getTime() < Date.now();
      },
      bloodInstrumentLevel: {
        lot_name: "",
        name_for_barcode: "",
        expires_at: "",
        mean: "",
        sd: "",
        qc_level_id: "",
      },
      editForm: false,
      rules: {
        lot_name: [
          {
            required: true,
            message: "Lot Name is Required",
            trigger: "blur",
          },
          {
            trigger: "blur",
          },
        ],
        name_for_barcode: [
          {
            required: true,
            message: "Name For Barcode is Required",
            trigger: "blur",
          },
        ],
        expires_at: [
          {
            required: true,
            message: "Expire Date is Required",
            trigger: "blur",
          },
        ],
        mean: [
          {
            required: true,
            message: "mean Date is Required",
            trigger: "blur",
          },
        ],
        sd: [
          {
            required: true,
            message: "sd Date is Required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  async mounted() {
    this.$store.commit("errors/clear", null, { root: true });
    await this.fetchLevels();

    if (this.$route.params.level_id) {
      this.editForm = false;
      this.showEditConfigInformation = true;
      this.fetchSingleInstrumentLevel();
    } else {
      this.editForm = true;
      this.showEditConfigInformation = false;
    }
  },
  methods: {
    async fetchLevels() {
      this.loading = true;

      await this.$store.dispatch("bloodQcLevels/fetchAllBloodQcLevelsData", {
        is_active: true,
        order_by: "level_name",
        order_type: "asc",
      });

      this.loading = false;
    },
    configFormView() {
      this.editForm = true;
      this.setLevelData();
      this.showEditConfigInformation = false;
    },
    setLevelData() {
      if (
        this.getSingleBloodInstrumentTestLevel &&
        this.getSingleBloodInstrumentTestLevel.data
      ) {
        this.bloodInstrumentLevel.lot_name =
          this.getSingleBloodInstrumentTestLevel.data.lot_name;
        this.bloodInstrumentLevel.mean =
          this.getSingleBloodInstrumentTestLevel.data.mean;
        this.bloodInstrumentLevel.sd =
          this.getSingleBloodInstrumentTestLevel.data.sd;
        this.bloodInstrumentLevel.name_for_barcode =
          this.getSingleBloodInstrumentTestLevel.data.name_for_barcode;
        this.bloodInstrumentLevel.expires_at =
          this.getSingleBloodInstrumentTestLevel.data.expires_at;
        this.bloodInstrumentLevel.qc_level_id = this
          .getSingleBloodInstrumentTestLevel.data.qc_level_id
          ? this.getSingleBloodInstrumentTestLevel.data.qc_level_id._id
          : null;
      }
    },
    prepareParams() {
      return {
        lot_name: this.bloodInstrumentLevel.lot_name,
        qc_level_id: this.bloodInstrumentLevel.qc_level_id,
        mean: this.bloodInstrumentLevel.mean,

        name_for_barcode: this.bloodInstrumentLevel.name_for_barcode,
        expires_at: this.bloodInstrumentLevel.expires_at,

        sd: this.bloodInstrumentLevel.sd,
      };
    },
    async fetchSingleInstrumentLevel() {
      this.loading = true;
      await this.$store.dispatch("bloodTestLevels/fetchSingleInstrumentLevel", {
        test_id: this.$route.params.test_id,
        level_id: this.$route.params.level_id,
      });
      this.setLevelData();
      this.showEditConfigInformation = true;
      this.loading = false;
    },
    async saveTestLevel() {
      if (this.levelId) {
        // this.askReason();
        this.updateBloodTesLevel();
      } else {
        this.addBloodTestLevel();
      }
    },
    async addBloodTestLevel() {
      let params = this.prepareParams();
      (params.test_id = this.$route.params.test_id),
        (params = this.getFormattedValues(params));

      this.buttonLoading = true;

      await this.$store.dispatch(
        "bloodTestLevels/saveBloodInstrumentTestLevel",
        params
      );
      this.buttonLoading = false;
      if (this.getCreateTestLevelStatus) {
        successNotification("Instrument Test Level Created Successfully");
        this.goToInstrumentTestLevels();
      } else if (!this.getErrors) {
        errorNotification(this.getError || "Error in Creating Test Level");
      }
    },
    async askReason() {
      this.takeReasonDialog = true;
    },
    async updateBloodTesLevel() {
      try {
        let params = this.prepareParams();
        params.test_id = this.$route.params.test_id;
        params.level_id = this.levelId;
        params = this.getFormattedValues(params);

        this.buttonLoading = true;
        await this.$store.dispatch(
          "bloodTestLevels/updateBloodInstrumentTestLevel",
          params
        );
        this.buttonLoading = false;
        if (this.getUpdateTestLevelStatus) {
          successNotification("Instrument Test Level Updated Successfully");
          this.$router.push({
            name: "All-Blood-Instrument-Test-Level",
            query: { ...this.$route.query, redirectRoute: this.$route.name },
          });
        } else if (this.getError) {
          errorNotification(this.getError);
        }
      } catch (error) {
        console.log(error);
      }
    },
    cancelCreation() {
      if (this.$route.params.level_id) {
        this.editForm = false;
        this.showEditConfigInformation = true;
      } else {
        this.goToInstrumentTestLevels();
      }
    },
    goToInstrumentTestLevels() {
      this.$router.push({
        name: "All-Blood-Instrument-Test-Level",
        params: {
          test_id: this.$route.params.test_id,
        },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
  },
};
</script>

<style lang="scss">
#add-bloodinstrument-test-level {
  .case-creation__heading .title-with-icon {
    width: 100%;
    max-width: 315px;
  }
  .edit-block {
    .edit-btn {
      border: none;
      padding: 0;
      min-height: 30px;
      .edit-icon {
        display: flex;
        align-items: center;
        margin-right: 10px;
        color: #111111;

        img {
          margin-right: 6px;
          width: 18px;
          margin-bottom: 3px;
          height: 18px;
        }
      }
      &:hover {
        background-color: transparent !important;
        color: #111111;
      }
    }
  }
}
</style>
