export default {
  BLOOD_INSTRUMENTS: [
    {
      name: "Roche",
      value: "Roche",
    },
    {
      name: "DiaSorin",
      value: "DiaSorin",
    },
    {
      name: "Siemens",
      value: "Siemens",
    },
    {
      name: "Alcor",
      value: "Alcor",
    },
    {
      name: "Sysmex",
      value: "Sysmex",
    },
  ],

  FILTER_STATUS: [
    {
      name: "All",
      value: "all",
    },
    {
      name: "Active",
      value: true,
    },
    {
      name: "In Active",
      value: false,
    },
  ],
};
