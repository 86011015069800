<template>
  <section class="all-cases-view">
    <div class="container-default">
      <el-row justify="center">
        <el-col :span="20">
          <div class="primary-content-view-new" v-loading="loading">
            <div
              class="listings listings__table listings__all-cases"
              id="all-cases-listings"
            >
              <el-table
                :data="testData.data"
                :default-sort="{
                  prop: orderBy,
                  order: orderType == 'asc' ? 'ascending' : 'descending',
                }"
                @sort-change="onChangeSort"
              >
                <el-table-column
                  prop="instrument"
                  label="Instrument"
                  min-width="100"
                  sortable="custom"
                >
                  <template v-slot="scope">
                    <span @click="onView(scope.row)" class="cursor"
                      >{{ scope.row.instrument }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="test_name_code"
                  label="Test Name"
                  min-width="100"
                  sortable="custom"
                >
                  <template v-slot="scope">{{
                    scope.row.test_name_code ? scope.row.test_name_code : "--"
                  }}</template>
                </el-table-column>
                <el-table-column
                  prop="result_name"
                  label="Result Name"
                  min-width="150"
                >
                  <template v-slot="scope">{{
                    scope.row.result_name
                  }}</template>
                </el-table-column>

                <el-table-column prop="units" label="Units" min-width="50">
                  <template v-slot="scope">{{
                    scope.row.units ? scope.row.units : "--"
                  }}</template>
                </el-table-column>
                <el-table-column prop="gender" label="Gender" max-width="60">
                  <template v-slot="scope">
                    {{ scope.row.gender }}
                  </template>
                </el-table-column>
                <el-table-column label="Reference Range" max-width="120">
                  <template v-slot="scope">
                    <div class="name-accession text-center-w-100">
                      <span
                        id="accession-id-blue"
                        class="cursor"
                        @click="viewAbnormalFlags(scope.row)"
                      >
                        {{ scope.row.reference_range }}
                      </span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="action"
                  label="Actions"
                  width="90"
                  fixed="right"
                >
                  <template v-slot="scope">
                    <ul class="listings__action-buttons">
                      <li @click="onView(scope.row)">
                        <el-button class="view-button">
                          <img
                            class="action-active"
                            src="@/assets/images/icons/actions/view-icon.svg"
                            alt="icon"
                          />
                          <img
                            class="action-inactive"
                            src="@/assets/images/icons/actions/view-inactive.svg"
                            alt="icon"
                          />
                        </el-button>
                      </li>
                    </ul>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="listings listings__pagination">
              <el-pagination
                v-model:currentPage="page"
                v-model:page-size="pageSize"
                :page-sizes="[10, 25, 50, 100]"
                :small="small"
                :disabled="disabled"
                :background="background"
                layout=" prev, pager, next, jumper, sizes ,total"
                :total="testData.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div id="existed-case-information">
      <div class="default-modal">
        <el-dialog
          class="existed-caseinfo-dialog"
          v-model="viewAbnormalFlagsModal"
          width="40%"
          :before-close="handleFlagsModalClose"
        >
          <div class="dialog-header">
            <span class="header-part">
              {{
                `Abnormal Flags - ${
                  selectedResult && selectedResult.test_name_code
                    ? selectedResult.test_name_code
                    : ""
                }`
              }}
            </span>
            <el-button class="close-btn" @click="handleFlagsModalClose">
              <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
            /></el-button>
          </div>
          <div class="all-cases-view">
            <div class="popup-date pb-0">
              <div class="primary-content-view-new" v-loading="loading">
                <div
                  class="listings listings__table listings__all-cases"
                  id="all-cases-listings"
                  v-if="selectedResult && selectedResult.abnormal_flages"
                >
                  <el-table
                    :data="selectedResult.abnormal_flages"
                    style="width: 100%"
                  >
                    <el-table-column prop="upper_limit" label="Upper Limit">
                    </el-table-column>
                    <el-table-column prop="lower_limit" label="Lower Limit">
                    </el-table-column>
                    <el-table-column prop="flag" label="Flag">
                    </el-table-column>
                  </el-table>

                  <div class="dialog-footer">
                    <ul>
                      <li>
                        <el-button
                          class="blue-save-button"
                          @click="handleFlagsModalClose"
                          >OK</el-button
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </section>
</template>

<script>
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import { mapGetters } from "vuex";
import FilterHelper from "@/mixins/FilterHelper";
export default {
  mixins: [UserPermissionsHelper, FilterHelper],

  data() {
    return {
      page: 1,
      loading: false,
      pageSize: 25,
      testData: {},
      total: 0,
      orderTypeMap: {
        ascending: "asc",
        descending: "desc",
      },
      orderBy: "created_at",
      orderType: "desc",
      viewAbnormalFlagsModal: false,
      selectedResult: null,
    };
  },
  computed: {
    ...mapGetters("bloodResults", ["getAllBloodResultConfig"]),
    ...mapGetters("errors", ["getErrors", "getError"]),
  },

  async mounted() {
    await this.setQueryParams();
    await this.fetchBloodResultConfig(this.page);
  },

  methods: {
    onChangeSort({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "asc" : "desc";
      } else {
        this.orderBy = "created_at";
        this.orderType = "DESC";
      }
      this.fetchBloodResultConfig(this.page);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchBloodResultConfig(this.page);
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchBloodResultConfig(this.page);
    },
    setQueryParams() {
      this.page = this.$route.query.selectedPageSize
        ? parseInt(this.$route.query.selectedPageSize)
        : 1;
      this.pageSize = this.$route.query.selectedResultPageSize
        ? parseInt(this.$route.query.selectedResultPageSize)
        : 25;
    },
    searchString() {
      setTimeout(() => {
        this.fetchBloodResultConfig(1);
      }, 600);
    },
    prepareParams(page) {
      let params = { page: page, limit: this.pageSize };

      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }

      return params;
    },

    async fetchBloodResultConfig(page, applyQuery = true) {
      this.page = page;
      this.loading = true;
      let params = this.prepareParams(page);
      await this.$store.dispatch(
        "bloodResults/fetchAllBloodResultConfig",
        params
      );
      this.testData = JSON.parse(JSON.stringify(this.getAllBloodResultConfig));
      if (applyQuery) {
        this.updateQueryParams(params);
      } else {
        this.updateQueryParam(this.$route.name, {}, true);
      }
      this.loading = false;
    },
    updateQueryParams(params) {
      let selectedParams = {
        selectedResultPage: params.page,
        selectedResultPageSize: params.limit,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },
    onView(config) {
      this.$router.push({
        name: "BloodResultsEdit",
        params: { config_id: config._id },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },

    viewAbnormalFlags(result) {
      this.viewAbnormalFlagsModal = true;
      this.selectedResult = result;
    },
    handleFlagsModalClose() {
      this.viewAbnormalFlagsModal = false;
      this.selectedResult = null;
    },
  },
};
</script>

<style lang="scss">
.all-cases-view {
  table {
    thead {
      tr {
        th:first-child {
          .cell {
            padding-left: 1em !important;
          }
        }
      }
    }
    tbody {
      tr {
        td:first-child {
          .cell {
            a {
              padding-left: 1em;
              color: #1349a5 !important;
            }
          }
        }
      }
    }
  }
  .content {
    margin-top: 20px;
    background: #fff;
  }
}
</style>
