<template>
  <section id="blood-results-config" class="case-creation" v-loading="loading">
    <div class="container-small-table">
      <div class="case-creation__step case-creation__patient-information">
        <div class="case-creation__card">
          <div class="case-creation__heading">
            <div class="title-with-icon">
              <span class="icon">
                <img
                  src="@/assets/images/icons/icon-patient-info.svg"
                  alt="icon"
                  class="img-fluid"
                />
              </span>
              <h3 class="title">Blood Config Information</h3>
            </div>
            <div class="edit-block" v-if="showEditInformation">
              <el-button class="edit-btn" @click="caseFormView">
                <span class="edit-icon">
                  <img
                    src="@/assets/images/icons/edit-case-information.svg"
                    alt=""
                  />
                  <p>EDIT</p>
                </span>
              </el-button>
            </div>
          </div>
          <div class="case-creation__body pb-0">
            <div class="content" v-if="editForm">
              <el-form ref="bloodConfigInfo" :model="bloodConfigInfo">
                <el-row :gutter="30">
                  <el-col :xl="6" :lg="6" :md="6" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">Instrument</label>
                        <el-input
                          v-model="bloodConfigInfo.instrument"
                          :disabled="true"
                        >
                        </el-input>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :xl="6" :lg="6" :md="6" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">Instrument Code</label>
                        <el-input
                          v-model="bloodConfigInfo.instrument_code"
                          :disabled="true"
                        ></el-input>
                        <p class="err">
                          <FormError errorName="instrument_code"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="6" :lg="6" :md="6" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">Test Name / Code</label>
                        <el-input
                          v-model="bloodConfigInfo.test_name_code"
                          :disabled="true"
                        ></el-input>
                        <p class="err">
                          <FormError errorName="test_name_code"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="6" :lg="6" :md="6" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">Result Name</label>
                        <el-input
                          v-model="bloodConfigInfo.result_name"
                          :disabled="true"
                        ></el-input>
                        <p class="err">
                          <FormError errorName="result_name"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>

                  <el-col :xl="6" :lg="6" :md="6" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">Units</label>
                        <el-input v-model="bloodConfigInfo.units"></el-input>
                        <p class="err">
                          <FormError errorName="units"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="6" :lg="6" :md="6" :sm="12" :xs="24">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">
                          Orderable
                          <span class="star">*</span>
                        </label>
                        <div class="gender-select">
                          <el-select
                            v-model="bloodConfigInfo.isorderable"
                            placeholder="Select Orderable"
                          >
                            <el-option
                              v-for="(orderable, index) in orderableOptions"
                              :key="index"
                              :label="orderable.label"
                              :value="orderable.value"
                            ></el-option>
                          </el-select>
                          <p class="err">
                            <FormError errorName="isorderable"></FormError>
                          </p>
                        </div>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="6" :lg="6" :md="6" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">Lower Limit</label>
                        <el-input
                          v-model="bloodConfigInfo.lower_limit"
                        ></el-input>
                        <p class="err">
                          <FormError errorName="lower_limit"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :xl="6" :lg="6" :md="6" :sm="12" :xs="12">
                    <div class="case-creation__input">
                      <el-form-item class="mb-0">
                        <label for="text">Upper Limit</label>
                        <el-input
                          v-model="bloodConfigInfo.upper_limit"
                        ></el-input>
                        <p class="err">
                          <FormError errorName="upper_limit"></FormError>
                        </p>
                      </el-form-item>
                    </div>
                  </el-col>
                </el-row>
              </el-form>
            </div>

            <div
              class="case-view-information"
              v-else-if="
                getSingleResultConfig &&
                Object.keys(getSingleResultConfig).length > 0
              "
            >
              <el-row :gutter="30">
                <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Instrument</h4>
                    <p class="value">{{ bloodConfigInfo.instrument }}</p>
                  </div>
                </el-col>

                <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Instrument Code</h4>
                    <p class="value">{{ bloodConfigInfo.instrument_code }}</p>
                  </div>
                </el-col>
                <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Test Name / Code</h4>
                    <p class="value">{{ bloodConfigInfo.test_name_code }}</p>
                  </div>
                </el-col>
                <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Result Name</h4>
                    <p class="value">{{ bloodConfigInfo.result_name }}</p>
                  </div>
                </el-col>

                <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Units</h4>
                    <p class="value" v-if="bloodConfigInfo.units">
                      {{ bloodConfigInfo.units }}
                    </p>
                    <p class="value" v-else>---</p>
                  </div>
                </el-col>
                <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="24">
                  <div class="input-value view-cases">
                    <h4 class="name">Orderable</h4>
                    <p class="value" v-if="bloodConfigInfo.isorderable">Yes</p>
                    <p class="value" v-else>No</p>
                  </div>
                </el-col>
                <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="24">
                  <div class="input-value view-cases">
                    <h4 class="name">Lower Limit</h4>
                    <p class="value" v-if="bloodConfigInfo.lower_limit">
                      {{ bloodConfigInfo.lower_limit }}
                    </p>
                    <p class="value" v-else>---</p>
                  </div>
                </el-col>
                <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="24">
                  <div class="input-value view-cases">
                    <h4 class="name">Upper Limit</h4>
                    <p class="value" v-if="bloodConfigInfo.upper_limit">
                      {{ bloodConfigInfo.upper_limit }}
                    </p>
                    <p class="value" v-else>---</p>
                  </div>
                </el-col>
              </el-row>
            </div>
            <p class="err" v-if="getErrors && getErrors.title">
              <FormError errorName="critical_error"></FormError>
            </p>
          </div>
        </div>
        <div class="case-creation__actions" v-if="editForm && !loading">
          <ul class="list">
            <li>
              <el-button
                type="default"
                class="btn cancel-button"
                @click="cancelCreation"
                >Cancel</el-button
              >
            </li>
            <li>
              <el-button
                type="success"
                class="btn save-button"
                @click="updateConfig"
                :loading="buttonLoading"
              >
                Save
              </el-button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
const FormError = defineAsyncComponent(() =>
  import("@/components/misc/FormError")
);
export default {
  data() {
    return {
      orderableOptions: [
        {
          value: true,
          label: "Yes",
        },
        {
          value: false,
          label: "No",
        },
      ],
      bloodConfigInfo: {},
      configId: "",
      editForm: false,
      showEditInformation: true,
      loading: false,
    };
  },
  components: { FormError },
  computed: {
    ...mapGetters("bloodResults", [
      "getSingleResultConfig",
      "getBloodResultUpdateStatus",
    ]),
    ...mapGetters("errors", ["getErrors", "getError"]),
  },
  async mounted() {
    this.$store.commit("errors/clear", null, { root: true });
    this.configId = this.$route.params.config_id;
    await this.fetchSingleBloodConfigDetails();
  },

  methods: {
    caseFormView() {
      this.editForm = true;
      this.showEditInformation = false;
      this.$store.commit("errors/clear", null, { root: true });
      this.bloodConfigInfo = JSON.parse(
        JSON.stringify(this.getSingleResultConfig)
      );
    },

    async fetchSingleBloodConfigDetails() {
      this.loading = true;
      await this.$store.dispatch(
        "bloodResults/fetchSingleBloodConfig",
        this.configId
      );
      this.bloodConfigInfo = JSON.parse(
        JSON.stringify(this.getSingleResultConfig)
      );

      this.loading = false;
    },
    async updateConfig() {
      this.loading = true;
      let params = this.bloodConfigInfo;
      params.configId = this.configId;
      await this.$store.dispatch("bloodResults/updateResults", params);
      if (this.getBloodResultUpdateStatus) {
        successNotification("Blood Test Config updated Successfully");
        this.cancelCreation();
      } else if (!this.getErrors) {
        errorNotification(
          this.getError || "Error in Updatinf Blood Test Config"
        );
      }
      this.loading = false;
    },
    cancelCreation() {
      this.editForm = false;
      this.showEditInformation = true;
    },
  },
};
</script>

<style lang="scss">
#blood-results-config {
  .case-creation__heading .title-with-icon {
    width: 100%;
    max-width: 250px;
  }
  .edit-block {
    .edit-btn {
      border: none;
      padding: 0;
      min-height: 30px;
      .edit-icon {
        display: flex;
        align-items: center;
        margin-right: 10px;
        color: #111111;

        img {
          margin-right: 6px;
          width: 18px;
          margin-bottom: 3px;
          height: 18px;
        }
      }
      &:hover {
        background-color: transparent !important;
        color: #111111;
      }
    }
  }
}
</style>
