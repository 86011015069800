<template>
  <section v-loading="loading" id="blood-qc-charts-filters">
    <div class="filter-bar">
      <el-form class="filterForm">
        <el-row :gutter="20" type="flex" align="top" justify="center">
          <el-col :xl="6" :lg="6" :sm="24" :xs="24">
            <div class="each-table-data-edit">
              <el-form-item :required="true" class="mb-05">
                <label for="text">
                  Start Date - End Date
                  <span class="star">*</span>
                </label>
                <el-date-picker
                  v-model="startDate"
                  type="daterange"
                  range-separator="To"
                  start-placeholder="From Date"
                  end-placeholder="To Date"
                  :picker-options="datePickerOptions"
                  format="MM-DD-YYYY"
                  value-format="YYYY-MM-DD"
                  :disabled-date="disabledDate"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </el-col>
          <el-col :xl="6" :lg="6" :sm="24" :xs="24">
            <div class="each-table-data-edit">
              <el-form-item :required="true" class="mb-05">
                <label for="text">
                  Instrument
                  <span class="star">*</span>
                </label>
                <el-select
                  placeholder="Select Instrument"
                  v-model="selectedInstrument"
                  :filterable="true"
                  :clearable="true"
                  :filter-method="filterInstruments"
                  @focus="filterInstruments()"
                  @change="fetchBloodTests()"
                >
                  <el-option
                    v-for="status in filteredInstruments"
                    :key="status.value"
                    :value="status.value"
                    :label="status.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :xl="6" :lg="6" :sm="24" :xs="24">
            <div class="each-table-data-edit">
              <el-form-item :required="true" class="mb-05">
                <label for="text">
                  Test
                  <span class="star">*</span>
                </label>
                <el-select
                  v-model="selectedTest"
                  placeholder="Select Test"
                  :disabled="!selectedInstrument"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(test, index) in testOptions"
                    :key="index"
                    :label="test.assay_name"
                    :value="test._id"
                  >
                    <span style="float: left">{{ test.assay_name }}</span>
                    <span
                      style="float: right; color: #8492a6; font-size: 13px"
                      >{{ test.test_name }}</span
                    >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :xl="6" :lg="6" :sm="24" :xs="24">
            <div class="each-table-data-edit">
              <el-form-item :required="true">
                <label for="text">
                  QC Level
                  <span class="star">*</span>
                </label>
                <el-select
                  v-model="selectedLevel"
                  placeholder="Select QC Level"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(level, index) in levelOptions"
                    :key="index"
                    :label="level.level_name"
                    :value="level.level_name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :xl="24" :lg="24">
            <div class="qc-chart-buttons">
              <el-button
                @click="generateData"
                class="generate"
                :disabled="disableGenerateCharts"
                >Generate</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div v-if="this.bloodQcdata && this.bloodQcdata.length">
      <div v-for="test in bloodQcdata" :key="test.test + test.level">
        <el-card
          class="box-card mb-10"
          v-for="lot in test.qc_data"
          :key="test.test + test.level + lot.lot"
        >
          <QCChart
            v-if="lot && lot.data && lot.data.length"
            :containerId="test.test + test.level + lot.lot"
            :chartData="lot"
            :chartTitle="test"
          ></QCChart>
        </el-card>
      </div>
    </div>

    <div class="text-center pt-30" v-if="noBloodQCData">
      <div class="empty-msg">
        <img
          src="@/assets/img/icons/empty.png"
          alt="empty"
          class="image"
          width="50"
        />
        <p class="mt-30">
          Sorry we couldn't find any <br />
          matches for that <br />
          <span class="black6 body-fs">Please select with another options</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import ChartHelper from "@/mixins/ChartsHelper";
// import Highcharts from "@/config/highcharts";
import { mapGetters } from "vuex";
import QCChart from "./QCChart.vue";
import bloodConstants from "@/config/constants/bloodConstants";
import moment from "moment";

window.qc1Chart = null;
window.qc2Chart = null;
window.qc3chart = null;

export default {
  mixins: [ChartHelper],
  props: ["width"],
  components: {
    QCChart,
  },
  data() {
    return {
      loading: false,
      noData: false,
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      options: ["LEVEL1", "LEVEL2"],
      test: ["PTT", "PT"],
      startDate: "",
      selectedTest: "",
      selectedInstrument: "",
      selectedLevel: "",
      bloodQcdata: null,
      qcData: null,
      bloodQc: [],
      id: "",
      chartContainers: [],
      filteredInstruments: [],
      instruments: bloodConstants.BLOOD_INSTRUMENTS,
    };
  },
  computed: {
    noBloodQCData() {
      if (
        this.getBloodQCChartReportData &&
        this.getBloodQCChartReportData.length
      ) {
        return true;
      }
      return false;
    },
    ...mapGetters("qcCharts", [
      "getQCChartReportData",
      "getQCChartReportValidationErrors",
      "getConfirmationTests",
      "getQCResultFiles",
      "getBloodQCChartReportData",
      "getBloodQCChartTests",
    ]),
    ...mapGetters("bloodQcLevels", ["getAllBloodQcLevels"]),
    ...mapGetters("bloodConfig", ["getAllInstrumentTestConfig"]),
    ...mapGetters("profile", ["getProfileData"]),
    disableGenerateCharts() {
      if (
        this.startDate &&
        this.startDate.length == 2 &&
        this.selectedTest &&
        this.selectedLevel
      ) {
        return false;
      }
      return true;
    },
    levelOptions() {
      if (
        this.getAllBloodQcLevels &&
        this.getAllBloodQcLevels.data &&
        this.getAllBloodQcLevels.data.length
      ) {
        return this.getAllBloodQcLevels.data;
      }
      return [];
    },
    testOptions() {
      if (
        this.getAllInstrumentTestConfig &&
        this.getAllInstrumentTestConfig.data &&
        this.getAllInstrumentTestConfig.data.length
      ) {
        return this.getAllInstrumentTestConfig.data;
      }
      return [];
    },
  },
  async mounted() {
    this.loading = true;
    await this.fetchBloodQCLevels();
    this.filterInstruments();
    this.loading = false;
  },
  methods: {
    async fetchBloodQCLevels() {
      try {
        let params = {
          get_all: true,
          order_by: "level_name",
          order_type: "asc",
          is_active: true,
        };
        await this.$store.dispatch(
          "bloodQcLevels/fetchAllBloodQcLevelsData",
          params
        );
      } catch (error) {
        console.log(error);
      }
    },
    async fetchBloodTests() {
      try {
        this.selectedTest = "";
        let params = {
          get_all: true,
          order_by: "assay_name",
          order_type: "asc",
          is_active: true,
        };
        if (this.selectedInstrument) {
          params.instrument = this.selectedInstrument;
        }
        await this.$store.dispatch(
          "bloodConfig/fetchAllBloodTestConfig",
          params
        );
      } catch (error) {
        console.log(error);
      }
    },
    filterInstruments(filterValue) {
      if (filterValue && filterValue.length) {
        this.filteredInstruments = [...this.instruments].filter(
          (option) =>
            option.name.toLowerCase().indexOf(filterValue.toLowerCase()) == 0
        );
      } else {
        this.filteredInstruments = this.instruments;
      }
      this.sortInstruments();
    },
    sortInstruments() {
      this.filteredInstruments.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    async generateData() {
      try {
        this.loading = true;
        let testData = this.testOptions.find(
          (test) => test._id == this.selectedTest
        );
        let params = {
          assay_name: testData.assay_name ? testData.assay_name : undefined,
          instrument: testData.instrument ? testData.instrument : undefined,
          test_name: testData.test_name ? testData.test_name : undefined, //category
        };
        if (this.startDate[0]) {
          params.from_date = moment
            .tz(this.getProfileData.lab.timezone)
            .set(this.getLabTimeZoneData(this.startDate[0]))
            .startOf("day")
            .utc()
            .format();
        }
        if (this.startDate[1]) {
          params.to_date = moment
            .tz(this.getProfileData.lab.timezone)
            .set(this.getLabTimeZoneData(this.startDate[1]))
            .endOf("day")
            .utc()
            .format();
        }
        if (this.selectedLevel) {
          params.level = this.selectedLevel;
        }
        this.noData = false;
        await this.$store.dispatch("qcCharts/fetchAllBloodQcChartData", params);
        if (
          this.getBloodQCChartReportData &&
          this.getBloodQCChartReportData.data &&
          this.getBloodQCChartReportData.data.length
        ) {
          this.bloodQcdata = JSON.parse(
            JSON.stringify(this.getBloodQCChartReportData.data)
          );
        } else {
          this.noData = true;
        }

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
#blood-qc-charts-filters {
  width: 94%;
  margin: auto;
  .filter-bar {
    padding: 2rem 0;
    .filterForm {
      .each-table-data-edit {
        position: relative;

        .el-form-item__content {
          color: #223147;
          line-height: 30px;
          font-weight: 500;
        }
        .el-input--suffix .el-input__inner {
          background: #ffffff;
          border: 1px solid #e0e0e1;
          border-radius: 5px;
        }
      }

      .el-form-item {
        display: block;
      }
    }
  }
  .results-section {
    margin-top: 2rem;
    .results-section-header {
      display: flex;
      align-items: center;
      border: 1.2px solid #e7e7f7;
      border-radius: 5px;
      margin-bottom: 1rem;

      padding: 5px;
      .drug-section {
        h3 {
          color: rgba(0, 0, 0, 0.9);
          font-size: 16px;
          padding: 0 10px;
          .value {
            color: #0a5da6;
          }
        }
      }
      .date-section {
        display: flex;
        align-items: center;

        h3 {
          color: rgba(0, 0, 0, 0.9);
          padding: 0 10px;
          font-size: 16px;

          .value {
            color: #0a5da6;
            font-size: 15px;
            padding: 0 5px;
          }
        }
      }
    }
    .el-card.is-always-shadow {
      box-shadow: none;
    }
  }

  .text-center {
    .empty-msg {
      display: inline-block;

      padding: 1em;

      margin-top: 5%;
    }

    #qc-chart-print-view {
      width: 900px;
    }
  }
  .qc-chart-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    .generate {
      background-color: #0a5da6;
      color: #ffffff;
      border: none;
      width: 175px;
    }
  }
}
</style>
